/* You can add global styles to this file, and also import other style files */
body, html {
  height: 100%;
  position: relative;
}

@font-face {
  font-family: "Glacial Indifference";
  src: local("Glacial Indifference"),url(./assets/fonts/GlacialIndifference-Regular.otf) format("truetype");
}

/* Provide sufficient contrast against white background */

.input-error {
  color: red;
}

code {
  color: #e01a76;
}

  .navbar-nav .nav-item.dropdown .dropdown-toggle::after {
    vertical-align: 0.125em;
  }

.slick-slider {
  width: 88%;
  margin: auto;
}

body .slick-prev,
body .slick-next {
  height: 45px;
  width: 40px;
  z-index: 100;
}

.slick-prev:before, .slick-next:before {
  font-size: 32px;
  line-height: 1;
  color: #C57A0F;
  opacity: .87;
}

h2.head {
  color: #1E293B;
  font-size: 30px;
}

h2.head, .left-top h2 span {
  font-family: 'Questrial',sans-serif;
}

span.sub-head.text-light {
  color: #9e7c49 !important;
  font-size: 1.125rem;
}

.toast-container {
  z-index: 2000;
}

.modal-backdrop {
  opacity: 0.8 !important;
}

.modal-dialog {
  max-width: 350px;
}

.modal-rounded, .modal-rounded > .modal-content {
  border-radius: 25px;
  padding: 1px;
}
