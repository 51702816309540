$min-contrast-ratio: 3;
$primary: #fae977;


@import "~bootstrap/scss/bootstrap.scss";
@import "_forms.scss";

@each $state, $data in $form-validation-states {
  @include form-validation-state($state, $data...);
}
